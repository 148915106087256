{
  "es": {
    "translation": {
      "annotation": {
        "delete": "Eliminar la anotación",
        "edit": "Editar",
        "empty_tags_list": "Las palabras clave aparecerán aquí",
        "no_content": "ningún contenido",
        "read_more": "Leer más",
        "switch_down": "Bajar la anotación",
        "switch_up": "Subir la anotación",
        "tags_list": "Filtrar las anotaciones que contienen las palabras clave",
        "target": "Resaltar"
      },
      "begin_msg": "Para comenzar a usar Adno, por favor ingrese la URL de un manifiesto o una imagen IIIF, una imagen estática en formato jpg o png, o un proyecto Adno",
      "buttons": {
        "cancel": "Cancelar",
        "close": "Cerrar"
      },
      "create_project": "Crear mi proyecto",
      "editor": {
        "approve_changes": "Aprobar los cambios",
        "audio_creator": "Creador de la pista de audio (Crédito)",
        "audio_track": "Pista de audio (URL)",
        "flip": "Voltear horizontalmente",
        "fullpage": "Poner en pantalla completa",
        "home": "Ir a inicio",
        "md_add_tag": "Agregar una palabra clave",
        "md_delete": "Eliminar",
        "md_delete_confirm": "Confirmar la eliminación",
        "md_save": "Guardar",
        "next_page": "Página siguiente",
        "placeholder": "Agregue su texto aquí",
        "previous_page": "Página anterior",
        "rotate_left": "Girar a la izquierda",
        "rotate_right": "Girar a la derecha",
        "tabs": {
          "audio": "Audio",
          "editor": "Editor",
          "tags": "Palabras claves"
        },
        "zoom_in": "ampliar",
        "zoom_out": "Alejar"
      },
      "errors": {
        "error_found": "Error detectado",
        "manifest_picture_not_found": "Error: No se encuentra el manifiesto o la imagen que ha ingresado",
        "no_iiif": "Proyecto no IIIF detectado, por favor proporcione un proyecto IIIF",
        "no_title": "Por favor, dé un título a su proyecto",
        "no_url": "No se proporcionó ninguna URL",
        "unable_access_file": "¡Imposible acceder a este archivo!",
        "unable_reading_file": "No se puede leer este archivo, por favor proporcione un archivo con uno de los siguientes formatos: png, jpg, json",
        "unable_reading_manifest": "Imposible leer el manifiesto",
        "unknown_characters": "Imposible leer las anotaciones (Caracteres prohibidos)",
        "wrong_file": "¡ADNO no puede leer este archivo! Inténtelo de nuevo con otro archivo",
        "wrong_url": "¡La URL proporcionada no es válida! Indique un enlace a un manifiesto o una imagen IIIF, una imagen JPG, PNG, o un archivo de un proyecto Adno"
      },
      "files": {
        "one_selected": "1 Archivo seleccionado"
      },
      "import": {
        "adno_project": "Proyecto Adno detectado, ¿desea importarlo?",
        "cancel": "Cancelar la importación",
        "import_now": "Utilizar el archivo de un proyecto",
        "import_project": "Importar un proyecto",
        "import_success": "Proyecto importado con éxito",
        "validate": "Validar la importación"
      },
      "link": {
        "generate": "Copiar URL",
        "h1": "Generador de URL",
        "preview": "Vista previa de la URL",
        "url": "Introduzca la URL a los recursos IIIF"
      },
      "modal": {
        "adno_proj_detected": "Proyecto Adno detectado, ¿desea importarlo?",
        "annotation_moved": "Anotación movida con éxito",
        "ask_delete": "¿Realmente desea eliminar esta anotación?",
        "cancel": "Cancelar",
        "confirm_del_annotation": "Sí, eliminar esta anotación",
        "confirm_delete": "Eliminar mi proyecto",
        "confirm_duplication": "Duplicar mi proyecto",
        "del_annotation_confirmation": "La anotación ha sido eliminada correctamente",
        "delete_project": "¿Realmente desea eliminar este proyecto?",
        "duplicate_project": "¿Realmente desea duplicar este proyecto?",
        "old_version": "Uno o varios proyectos se han hecho con una versión obsoleta de Adno",
        "project_edit_success": "¡Proyecto editado con éxito!",
        "projects_list_up_to_date": "¡La lista de proyectos se ha actualizado correctamente!",
        "settings_updated_success": "¡Configuraciones actualizadas con éxito!",
        "update_old_version": "Actualizar a la última versión",
        "version_updated": "¡Felicidades, tus proyectos Adno están actualizados!"
      },
      "navbar": {
        "back_home": "Volver a inicio",
        "download_project": "Descargar el proyecto",
        "edit_mode": "Modo edición",
        "edit_project": "Editar los metadatos",
        "redo": "Rehacer la acción deshecha",
        "share_project": "Guardar y compartir el proyecto",
        "share_project_desc1": "Primero descargue el archivo del proyecto. Para compartirlo, puede enviarlo por correo electrónico o subirlo en línea, ya sea en un sitio web, un servidor de archivos o en el sistema de archivos interplanetario IPFS, y luego difundir su URL o su CID IPFS.",
        "share_project_desc2": "Una vez que su proyecto Adno esté en línea, es posible hacerlo visible en una página web insertando una etiqueta iframe.",
        "share_project_desc3": "Para más información, consulte la documentación oficial de Adno.",
        "show_metadatas": "Ver los metadatos",
        "undo": "Deshacer la última acción",
        "view_mode": "Modo lectura"
      },
      "new_project": {
        "add_info": "Complete su proyecto"
      },
      "project": {
        "add_desc": "Descripción de su proyecto (opcional)",
        "add_title": "Dé un título a su proyecto",
        "author": "Autor",
        "back": "Volver",
        "back_home": "Cancelar y volver a inicio",
        "cancel": "Cancelar",
        "choose": "Seleccione una imagen",
        "choose_canva": "Usar esta imagen para mi proyecto",
        "choose_desc": "Elija la imagen que desea usar para su proyecto Adno",
        "copy": "copiar",
        "create": "Crear mi proyecto",
        "created_on": "Creado el ",
        "delete": "Eliminar",
        "description": "Descripción",
        "download": "Descargar",
        "duplicate": "Duplicar",
        "edit": "Editar",
        "editor": "Editor",
        "last_update": "Última actualización el ",
        "manifest_url": "URL",
        "metadatas": {
          "allocation_rights": "Asignación de derechos",
          "creation_date": "Fecha de creación",
          "format": "Formato",
          "identifier": "Nombre de usuario",
          "last_update": "Última modificación",
          "rights": "Derechos asignados",
          "source": "Fuente"
        },
        "metadatas_plh": {
          "allocation_rights": "Ingrese aquí los derechos de la obra",
          "author": "Indique aquí el autor",
          "description": "Añade una descripción a su proyecto",
          "editor": "Indique aquí el editor",
          "title": "Dé un título a este proyecto"
        },
        "new": "Crear mi proyecto",
        "new_tooltip": "Crear un nuevo proyecto",
        "no_creator": "No se indicó autor",
        "no_desc": "Ninguna descripción disponible para este proyecto",
        "no_editor": "No se indicó editor",
        "preview": "Vista previa",
        "settings": {
          "anno_bounds": "¿Ver las anotaciones?",
          "annos_nav": "Mostrar la barra de navegación para las anotaciones",
          "annotation": "Anotación",
          "annotation_sound": "Elija un modo para las anotaciones de audio",
          "begin_first_anno": "Siempre comenzar la reproducción automática en la primera anotación",
          "delay": "Plazo entre 2 anotaciones (en segundos)",
          "enable_rota": "Activar la rotación de la imagen",
          "fullscreen": "Mostrar barra de herramientas en modo pantalla completa",
          "navigation": "Navegación",
          "navigator": "Navegador de conjunto",
          "no_sound": "Las anotaciones de audio no se reproducirán",
          "no_spatialization": "Las anotaciones de audio se reproducirán individualmente",
          "outline_color": "Color de los contornos de las anotaciones",
          "outline_focus": "Color del contorno de la anotación actual",
          "outline_width": "Grosor de las anotaciones",
          "save": "Guardar",
          "show_eyes": "Mostrar un icono dentro de las formas",
          "show_outlines": "Mostrar los contornos",
          "spatialization": "Las anotaciones de audio se reproducirán con un efecto de espacialización",
          "tags": "Solo se mostrarán las anotaciones que incluyan la o una de las siguientes palabras clave",
          "toolsbar": "Mostrar la barra de herramientas",
          "visualization": "Visualización"
        },
        "title": "Título"
      },
      "projects": {
        "all": "Sus proyectos existentes",
        "nothing": "Ningún proyecto disponible por el momento"
      },
      "tags_infos": "Por favor, presione la tecla 'enter' para validar cada palabra clave",
      "visualizer": {
        "close": "Cerrar",
        "expand": "Poner en pantalla completa",
        "help": "Ayuda",
        "help_and": "y",
        "help_doc": "Para más información, consulte la documentación oficial de Adno.",
        "help_key": "tecla",
        "help_key_arrows": "flechas (izquierda y derecha) para cambiar la anotación actual.",
        "help_key_e": "para activar el modo de pantalla completa,",
        "help_key_escape": "para salir del modo de pantalla completa,",
        "help_key_p": "para iniciar automáticamente,",
        "help_key_plural": "teclas",
        "help_key_s": "para mostrar los contornos de las anotaciones,",
        "help_key_t": "para mostrar los iconos de navegación y visualización,",
        "help_or": "o",
        "help_title": "Atajos de teclado",
        "info": "Información",
        "next_annotation": "Anotación siguiente",
        "pause": "Pausar",
        "play": "Reproducir",
        "previous_annotation": "Anotación anterior",
        "reset_zoom": "Alejar",
        "rotation": "Girar 90 grados",
        "toggle_annotations": "Mostrar/ocultar las anotaciones"
      }
    }
  }
}
